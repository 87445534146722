<template>
  <MastHead
    :title="header"
    :image="picture"
    :description="subheader"
    :layout="layout"
  />
</template>

<script>
  import { AlchemyElement } from "~/mixins/alchemyVue"

  export default {
    mixins: [AlchemyElement],
    computed: {
      picture() {
        return this.getValue("picture")
      },
      subheader() {
        return this.getIngredient("subheader")?.strippedBody
      },
      header() {
        return this.getValue("header")
      },
      layout() {
        return this.getValue("layout")
      },
    },
  }
</script>
