<template>
  <div class="masthead">
    <template v-if="layout == 'default'">
      <Container>
        <Headline class="default-page-header-headline">
          {{ title }}
        </Headline>
      </Container>
      <img
        v-if="image"
        :src="image"
        :alt="imageAltText"
        class="default-image"
        loading="lazy"
      />
      <Container>
        <p class="default-page-header-description">
          {{ description }}
        </p>
      </Container>
    </template>
    <template v-if="layout == 'small'">
      <div
        :style="{
          backgroundImage: `url('${image}')`,
        }"
        class="small-image"
      >
        <Container class="small-content">
          <Headline :size="2" class="small-header" alignment="center">
            {{ title }}
          </Headline>
          <p class="small-description">
            {{ description }}
          </p>
        </Container>
      </div>
    </template>
  </div>
</template>

<script>
  import Headline from "~/components/Headline"

  export default {
    components: {
      Headline,
    },
    props: {
      title: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      image: {
        type: String,
        default: null,
      },
      imageAltText: {
        type: String,
        default: null,
      },
      layout: {
        type: String,
        validator(value) {
          return ["default", "small"].includes(value)
        },
        default: "default",
      },
    },
  }
</script>

<style lang="scss" scoped>
  .masthead {
    margin-bottom: $gutter-double;
  }

  .default-page-header-headline {
    margin: 0;
  }
  .default-page-header-description {
    @apply h3;
  }

  .default-image {
    width: 100%;
    height: 65vh;
    object-fit: cover;
    display: block;
  }

  .small-image {
    box-sizing: border-box;
    position: relative;
    color: white;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding: $space-xl 0;
    width: 100%;
    background-size: cover;
    background-position: center center;
    &:after {
      content: " ";
      background: rgba(0, 0, 0, $overlayColorOpacity);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .small-content {
    position: relative;
    z-index: 5;
    max-width: $content-width-small;
    padding: 0 $gutter;
  }

  .small-header {
    margin: 0;
  }

  .small-description {
    @apply text-lg leading-normal;
    margin: 0;
  }
</style>
